import { ComponentProps } from 'react';
import OriginSvg, { SvgProps } from 'react-native-svg';

import { isPrerender } from '../../deviceInfo';

export const Svg = (props: ComponentProps<typeof OriginSvg>) => {
  if (isPrerender()) {
    return null;
  }

  return <OriginSvg {...props} />;
};

export * from 'react-native-svg';

export type { SvgProps };

export default Svg;
