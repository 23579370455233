import { Component } from 'react';

import { coral } from '../../../constants/color';
import { G, Path, Svg } from '../../../utils/packages/reactNativeSvg';

const DEFAULT_SVG_SIZE = 24;

interface Props {
  size?: number;
  color?: string;
}

interface DefaultProps {
  size: number;
  color: string;
}

type PropsWithDefaults = Props & DefaultProps;

export default class IconFire extends Component<Props> {
  static defaultProps: DefaultProps = {
    size: DEFAULT_SVG_SIZE,
    color: coral,
  };

  render() {
    const { size, color } = this.props as PropsWithDefaults;
    const sizeString = size.toString();
    const scale = size / DEFAULT_SVG_SIZE;

    return (
      <Svg
        width={sizeString}
        height={sizeString}
        viewBox={`0 0 ${sizeString} ${sizeString}`}
      >
        <G scale={scale}>
          <Path
            /* eslint-disable max-len */
            d={
              'M15.22,21.47a7.88,7.88,0,0,0,3.92-5.94c.28-2.44-1.84-5.21-4.9-8-2.18-2-1.38-5.06-1.38-5.06S10.1,4,9.49,6.18a7.16,7.16,0,0,0,0,3.37c.15.61.61,1.23.46,1.38-.33.33-1.23-1.07-1.38-1.38A4,4,0,0,1,8.11,8c0-.1-3.9,3.23-3.19,7a9.76,9.76,0,0,0,4.31,6.25A.24.24,0,0,0,9.57,21a2.87,2.87,0,0,1-.08-2.41c.48-1,1.38-.91,2-2a4.83,4.83,0,0,0,.15-2.45c0-.1,1.73.94,1.84,1.84.15,1.23-.31,1.38-.46,1.84a.65.65,0,0,0,.46.92c.61.15.92-1.53.92-1.53a2.44,2.44,0,0,1,.92,2.6,3.36,3.36,0,0,1-.42,1.34A.24.24,0,0,0,15.22,21.47Z'
            }
            fill={color}
          />
        </G>
      </Svg>
    );
  }
}
