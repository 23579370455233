import { findBestAvailableLanguage } from 'react-native-localize';

import { DEFAULT_LANG, allowedLangs } from '../constants/misc';
import { isPrerender } from './deviceInfo';

declare global {
  interface Window {
    COUNTRY_CODE?: string;
  }
}

export default (): string => {
  // force return zh if prerender
  if (isPrerender()) {
    return 'zh';
  }
  // keep en for most users
  const languageTag =
    findBestAvailableLanguage(allowedLangs)?.languageTag || DEFAULT_LANG;

  return languageTag;
};

// set TW as default for developing
export const getCountryCode = () => window.COUNTRY_CODE || 'TW';
