import { translate } from '../i18n';

export enum SortBy {
  UPDATE_AT = 'updated_at',
  CREATE_AT = 'created_at',
  RELEVANCE = 'relevance',
  WEEKLY_HOT = 'weekly_hot',
  TOTAL_SALES_QUANTITY = 'total_sales_quantity',
}

export const sortText = {
  get [SortBy.UPDATE_AT]() {
    return translate('weekly-hot');
  },
  get [SortBy.WEEKLY_HOT]() {
    return translate('weekly-hot');
  },
  get [SortBy.CREATE_AT]() {
    return translate('latest');
  },
  get [SortBy.RELEVANCE]() {
    return translate('relevance');
  },
  get [SortBy.TOTAL_SALES_QUANTITY]() {
    return translate('relevance');
  },
};

export const defaultSortBy = SortBy.TOTAL_SALES_QUANTITY;

export const sortData = [
  SortBy.CREATE_AT,
  SortBy.WEEKLY_HOT,
  SortBy.TOTAL_SALES_QUANTITY,
];

export const translateSortFromId = (sortId?: string) => {
  switch (sortId) {
    case '8':
      return SortBy.WEEKLY_HOT;
    case '7':
      return SortBy.CREATE_AT;
    default:
      return defaultSortBy;
  }
};
