import { View } from 'react-native';

import FloatActionButton from '../elements/FloatActionButton';
import { isPrerender } from '../utils/deviceInfo';
import styles from './styles';
import { isWebPlatform } from './utils';

export default function () {
  if (!isWebPlatform || isPrerender()) {
    return null;
  }

  return (
    <View style={styles.floatIconStack}>
      <FloatActionButton />
    </View>
  );
}
