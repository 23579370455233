/* eslint-disable max-len */

import { LoginOrRegisterMethod } from '../../constants/LoginOrRegisterMethod';
import { CommonErrors, MemberErrors, TransactionErrors } from '../../constants/VBErrorCode';
import { MailType, SupportType, TransactionStatus } from '../../types/Common';
import { DeliveryMethods, PaymentMethods } from '../../types/VBConfigResponse';
import { VendorType } from '../../types/VendorType';

const NUMBER_FORMAT = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default {
  'VeryBuy-title': 'VeryBuy Fashion | every style you need',
  'vb-title-suffix-long': 'VeryBuy Fashion | every style you need',
  'vb-title-suffix-medium': 'VeryBuy Fashion',
  'vb-title-suffix-short': 'VeryBuy',
  'vb-default-keyword': "Women's clothing, cheongsam, dresses, coats, dresses",
  'landing-page-desc': 'VeryBuy provides the latest "%{name}", many fashionable and popular styles, come to VeryBuy to buy it!',
  'VeryBuy-og-description': "Women's exclusive fashion shopping platform, with new products every day and daily surprise offers, VeryBuy covers all your daily, commuting, and date clothing. Come to VeryBuy to easily match your outfits!",
  'alert-default-positive-text': 'OK',
  'alert-default-negative-text': 'Cancel',
  'crash-alert-message': 'Sorry! We got system exception. Please try restarting it and we will solve it as soon as possible',
  'crash-alert-negative': 'Report',
  'crash-alert-positive': 'Restart',
  'webview-error-content': 'Sorry! This page is temporarily stuck',
  'webview-error-content-with-cvs': 'Please try again or choose another shipping method',
  'item-page-desc-meta': '%{title} has the best value at VeryBuy, and more %{categoryName} are waiting for you to discover!',
  'search-result-page-desc-meta': 'Search for %{queryText} at VeryBuy, and you will find everything you need to find %{queryText}. Verybuy helps you solve the problem of fashion, download the App and get exclusive discounts. %{queryText} products recommended by major internet celebrities are available at VeryBuy',
  'categories-list-page-desc-meta': 'The most complete %{cat} series! There are all the %{subCats} you are looking for, and a batch of %{cat} products are all available at VeryBuy!',
  'loading': 'Loading...',
  'later': 'Later',
  'total': 'total',
  'products-total': 'total',
  'country': {
    'Taiwan': 'Taiwan',
    'Singapore': 'Singapore',
    'Global': 'Global',
  },
  'categories-new-arrivals': 'New',
  'member-contact-auth': {
    'title': 'Verification code has sent',
    'subtitle': 'Insert the code to verify',
    'note': 'Due to system transmission problems, it may take a few seconds to one minute for the verification code to be sent. If you still cannot receive the verification code after five minutes, please contact customer service',
    'resend': 'Resend',
    'resend-retry': 'Retry in %{sec}',
  },
  'member-contact-auth-error': {
    '2002': 'The verification code has expired, please try again',
    '1017': 'Verification code incorrect, please try again',
  },
  'member-edit-tab': {
    'info': 'Info',
    'password': 'Password',
  },
  'love-page-tab': {
    'item': 'Item',
    'vendor': 'Vendor',
  },
  'faq-page-tab': {
    'top-faq': 'TOP FAQ',
    'more-topics': 'MORE TOPICS',
  },
  'vendor-type': {
    [VendorType.Seller]: 'Seller',
    [VendorType.GoodSeller]: 'Good Seller',
    [VendorType.Flagship]: 'Flagship Store',
  },
  'vendor-offline': 'Offline',
  'remove-isLoved': 'Remove',
  'explore-more-vendors': 'Explore more vendors',
  'into-the-vendor': 'Go',
  'freight-included': 'Shipping fee included',
  'cat-scroll-hint': 'Scroll to discover more!',
  'freight-discount-included': 'Shipping fee and discount included',
  'freight-not-included': 'Shipping fee not included',
  'api-common-error-msg': 'An error occurred! %{errorCode}',
  'api-common-error-msg-try-again': 'An error occurred!\nPlease try again',
  'verify-sms-error-message': {
    '1001': 'Cannot complete verification, please recheck your phone number',
    '1019': 'Verification code has been sent, please check your SMS inbox',
  },
  'login-fail': {
    [CommonErrors.ERR_NOT_FOUND]: 'Your account or password is wrong!',
    [MemberErrors.ERR_MEMBER_NEED_LOGIN_BY_APPLE]: 'This account needs to use third party(Facebook, Apple, etc.) login!',
    [MemberErrors.ERR_MEMBER_NEED_LOGIN_BY_FACEBOOK]: 'This account needs to use third party(Facebook, Apple, etc.) login!',
    [MemberErrors.ERR_MEMBER_NEED_LOGIN_BY_FACEBOOK_OR_APPLE]: 'This account needs to use third party(Facebook, Apple, etc.) login!！',
    [MemberErrors.ERR_MEMBER_NEED_LOGIN_BY_LINE]: 'This account needs to use third party(Facebook, Apple, etc.) login!！',
  },
  'register-fail-default': 'Oops! Register fail',
  'register-fail': {
    [MemberErrors.ERR_MEMBER_EMAIL_USED]: 'This email is already in use',
    [MemberErrors.ERR_MEMBER_PASSWORD_INSUFFICIENT_STRENGTH]: 'Password needs 8 ~ 16 characters, at least one number and one character',
    [MemberErrors.ERR_MEMBER_PASSWORD_INSUFFICIENT_LENGTH]: 'Password needs 8 ~ 16 characters, at least one number and one character',
    [MemberErrors.ERR_MEMBER_INCORRECT_EMAIL]: 'Incorrect email, please try again',
    [MemberErrors.ERR_MEMBER_BLACK_EMAIL]: 'Please use your real email to register',
  },
  'not-filled': 'Not filled',
  'not-selected': 'Not selected',
  'not-filled-gift': 'No data, fill out to get reward',
  'basicInfo': 'Basic Info',
  'basicInfo-name': 'Name',
  'basicInfo-nickname': 'Nickname',
  'search-title': '%{query} Recommendations',
  'basicInfo-gender': 'Gender',
  'basicInfo-birthday': 'Birthday',
  'basicInfo-placeholder': {
    'name': 'Please enter your real name',
    'nickname': 'Please enter your nickname',
    'gender': 'Please enter the gender',
    'birthday': 'Please enter the birthday',
    'footer-message': "* After filling out the birthday which can't be modified, we will send you a gift, $100, 15 days before your birthday",
    'footer-message-without-vcoin': "* After filling out the birthday which can't be modified",
  },
  'verify-failed': 'Failure',
  'verify-failed-reach-limit-content': "You've reached the limit,\nplease try again later",
  'verify-failed-exists-content': 'The email or mobile number has been taken,\nplease recheck and try again',
  'vendor-event-banner': 'Special Discounts',
  'vendor-product-amount': 'Total products: %{amount}',
  'vendor-score-no-data': 'No data',
  'vendor-score-reviews': 'Ratings',
  'vendor-score-quality': 'Quality',
  'vendor-score-shipping-perf': 'Shipping',
  'vendor-score-info': '5',
  'magnitude-description': 'Magnitude Description',
  'vendor-score-reviews-description': "The average review score representing all of the seller's products",
  'vendor-score-quality-description': 'The VeryBuy Fashion platform gives ratings based on “product quality” and “user feedback”. The more stars, the better the product quality, which is higher than the average.',
  'vendor-score-shipping-perf-description': 'The VeryBuy Fashion platform is based on the “average delivery time after the seller receives the order”. The more stars, the better the delivery speed, which is higher than the average.',
  'back-and-retry': 'Go back and retry',
  'contactInfo': 'Contact Info',
  'contactInfo-phone': 'Cell phone',
  'contactInfo-email': 'Email',
  'contactInfo-subscription': 'Subscribe',
  'contactInfo-edm-subscribed': 'EDM subscribed',
  'contactInfo-placeholder': {
    'phone': 'Please enter your number',
    'email': 'example@gmail.com',
    'footer-message': '※ Get $50 coupons after verifing the phone number and the email',
    'footer-message-verified': '※ Some of the field has been verified, please contact customer service if modification is needed',
    'subscription': 'VeryBuy Fashion EDM',
  },
  'member-edit-invalid-phone': 'Incorrect phone number!',
  'gender': {
    '1': 'Male',
    '2': 'Female',
    '3': 'Other',
  },
  'select-date': 'Select date',
  'unverified': 'Unverified',
  'verified': 'Verified',
  'verify': 'Verify',
  'none': 'None',
  'member-profile': 'Member Profile',
  'member-info-edit': 'Edit Basic Info',
  'member-contact-edit': 'Edit Contact',
  'successfully-saved': 'Saved',
  'watch-size': 'Size',
  'follow': 'Follow',
  'following': 'Following',
  'follow-fail': 'Oops! Something goes wrong, please try again.',
  'people-likes': '%{count} likes',
  'view-amount': '%{amount} people are viewing',
  'view-all': 'View All',
  'code': 'en-US',
  'name': 'English',
  'about': 'About',
  'version': 'Version',
  'device-id': 'Device ID',
  'member-phone-verify-error-message': {
    '1017': 'Incorrect verification code',
    '1019': 'Too much attempts, please try again later',
    '2002': 'Cannot complete verification, please set your phone number again',
  },
  'fitting-report': 'Fitting report',
  'product-detail-title': 'Product Info',
  'product-detail-videos-title': 'Product Videos',
  'copied-to-clipboard': 'Copied to clipboard',
  'copied-account-number': 'Copied account number',
  'copied-transaction-no': 'Copied order no',
  'cat-tab-hint': 'Browse categories!',
  'me': 'My',
  'cart': 'Cart',
  'goto-cart': 'Cart',
  'category': 'Category',
  'keywords': 'Keywords',
  'verybuy': 'Verybuy',
  'new-arrivals-default-tab': 'New Arrivals',
  'index-on-sale-title-center': 'ON SALE',
  'index-on-sale-title-left': 'NEW DEALS',
  'index-on-sale-title-right': 'MORE',
  'leaveMsgInDialog': 'Are you sure leave without unsave?',
  'quit': 'Quit',
  'edit': 'Edit',
  'continueEdit': 'continue',
  'save': 'save',
  'burger-hint-title': 'Discover all categories!',
  'burger-hint-subtitle': 'Find your favorite using the category system!',
  'breakEdit': 'not stored',
  'reset': 'Reset',
  'filter': 'Filter',
  'apply': 'Apply',
  'filter-class': 'Categories',
  'search-labels-hint': 'Find products by label',
  'price-range': 'Price range(%{symbol})',
  'color-filter': 'Color',
  'pattern-filter': 'Pattern',
  'below-price': 'Below %{price}',
  'above-price': 'Above %{price}',
  'lowest-price': 'Lowest price',
  'category-by-style': {
    'title': '%{style}',
    'child-categories': 'Basic styles',
    'recommend-labels': 'Recommend styles',
  },
  'highest-price': 'Highest price',
  'sms-verify': 'We have sent the verification code to your phone number, contact us if cannot receive.',
  'refresh': 'Refresh',
  'new-message': 'New',
  'welcome-to-login-or-register': 'Welcome to register/login',
  'login-register': 'Login / Register',
  'logout': 'log out',
  'login': 'log in',
  'log-in-now': 'log in',
  'login-facebook': 'Continue with Facebook',
  'add-verify-phone-number-error': {
    '1018': 'This number has been used',
    '1016': 'The number is invalid, please check and try again',
  },
  'login-apple': 'Continue with Apple',
  'register': 'Sign up',
  'noAccountYet': "Haven't got an account?",
  'createOne': 'Create one',
  'subscribeNewsletter': 'Subscribe Newsletter(Receive special discounts from VeryBuy at any time!)',
  'registrationStandsForAgreement': "Register standing for you've read and agreed.",
  'memberTreaty': 'Member Treaty',
  'and': 'and',
  'privacyPolicy': 'Privacy Policy',
  'hasAccount': 'Has account?',
  'directlyLogin': 'Directly login',
  'on-sale-ad-title': 'ON SALE',
  'on-sale-ad-subtitle': '50% OFF',
  'on-sale-ad-btn': 'GO',
  'logoutConfirm': 'Are you sure to log out?',
  'nav-left-confirm': 'Are you sure to leave?',
  'event-task': 'Earn points',
  'event-task-rules': 'Rules',
  'event-task-button-text': {
    '0': 'Verify now',
    '1': 'Verify now',
    '2': 'Redeem',
    '3': 'Redeemed',
    '4': 'Verify now',
    '5': 'Redeemed',
    '6': 'Verify now',
    '7': 'Expired',
  },
  'view-my-vcoin': 'View my VCoin',
  'view-my-coupon': 'View my coupon',
  'event-task-hint': 'Please verify email and phone number to enable',
  'event-deadline': 'Deadline: %{date}',
  'task-rule': 'Task rule',
  'waiting-to-resend-verification-code': 'Re-send the verification code after %{number} seconds',
  'refetch-verify-code': 'Resend verification code',
  'not-receive-verification-code': 'Didn’t receive the verification code?',
  'confirm': 'Log out',
  'cancel': 'Cancel',
  'or': 'or',
  'guest-back-desc': 'Welcome back! We provide a better service for you, refresh the page and experience it immediately',
  'askLogin-desc': 'Sorry! Your login is invalid. Please try again.',
  'askLogin-positive': 'Login',
  'lightboxLoading-error-positive': 'ok',
  'transaction-error-default-desc': 'Sorry! The creating of the order list is failed. Please try again.',
  'welcomeLogin-header': 'New user exclusive',
  'welcomeLogin-promote': 'First order get NTD%{vcoin} shopping fee after paid.',
  'welcomeLogin-login-desc': 'Or log in by E-mail or register',
  'item-sku-modal-hint': 'APP orders over a certain amount will receive 10%',
  'item-sku-modal-hint-gc': 'Using the APP shopping experience is smoother!',
  'item-sku-modal-button': 'place an order in APP',
  'checkOrder': 'Check Order',
  'successOrder': 'Order success',
  'unpaid': 'Unpaid',
  'paid': 'Paid',
  'sent': 'Sent',
  'expired': 'Expired',
  'searchPlaceHolder': 'Search...',
  'creditCardPaymentPage': 'Card payment page',
  'home-search-placeholder': 'Download APP and get vouchers',
  'new-friends-gifts': 'new friends gifts',
  'register-campaign': 'Register Campaign',
  'item-page-label-section-title': 'Related search',
  'cart-missed': "You've missed",
  'too-slow': "You're too slow",
  'sold': 'SOLD',
  'hi': 'Hi',
  'hello': 'Sunshine',
  'account': 'Account',
  'email': 'E-mail',
  'password': 'Password',
  'form-validate-required': 'Required',
  'password-placeholder': 'Password needs 8 ~ 16 characters, at least one number and one character',
  'delivery-name-placeholder': 'Please enter your real name',
  'forgot-password': 'Forgot password?',
  'set-new-pwd-step1-form-title': 'Recover password',
  'set-new-pwd-step1-form-hint-1': 'please enter your VeryBuy account E-mail, we will send a password reset link to your mailbox.',
  'set-new-pwd-step1-form-hint-2': '<hint>※ If you forget your account, you can </hint><touch>register a new one</touch><hint>, or contact our customer service.</hint>',
  'set-new-pwd-mail-request-countdown': 'Did not get the mail? send again in (%{countdown}s)',
  'set-new-pwd-mail-request-success': 'Request success',
  'set-new-pwd-mail-request-failed-with-404': 'This account uses a third party (Facebook, Apple, etc.) to log in, the password cannot be reset, please go back and log in with a third party',
  'email-not-found': 'This E-mail is not registered.',
  'send-reset-password-mail': 'Send password reset mail',
  'reset-password-form-title': 'Reset Password',
  'reset-password-form-hint': 'Please enter you new password,it needs 8 ~ 16 characters, at least one number and one character.',
  'reset-password-success': 'Password changed successfully, please log in again',
  'reset-password-failed-dialog': {
    'title': 'Failed to change password',
    'content': 'You have exceeded the reset time limit. For the security of your account, please make another request to change your password.',
    'positive-text': 'Apply again',
  },
  'old-password': 'Old Password',
  'old-password-incorrect': 'Old password incorrect,\nplease recheck',
  'old-password-placeholder': 'Enter old password',
  'retry-input': 'Retry',
  'new-password': 'New Password',
  'new-password-placeholder': 'Enter new password',
  'new-password-placeholder-case2': 'Needs 8 ~ 16 characters for password',
  'confirm-new-password': 'Confirm Password',
  'confirm-new-password-placeholder': 'Enter new password again',
  'confirm-reset-password': 'Confirm changes',
  'confirm-password-different': 'Passwords does not match, please check again',
  'new-password-required': 'New password required',
  'old-password-required': 'Old password required',
  'new-password-same-err': 'New password cannot be same as old password',
  'new-password-confirm-err': 'New password confirm failed, please check and try again',
  'reset-password-invalid-title': 'Invalid Link',
  'reset-password-invalid-hint': 'The reset password link is invalid, please apply again.',
  'reset-password-apply-again': 'Apply again',
  'invalid-form-required': "It's a required field.",
  'invalid-login-email': 'Invalid of E-mail, eq：example@gmail.com',
  'invalid-login-password': 'Invalid of password, which needs 8 ~ 16 characters, at least one number and one character',
  'invalid-phone': 'Please input correct phone number.',
  'invalid-zip-code': 'Please input correct zip code',
  'login-fail-default-desc': 'Oops! Login failed. Please try again.',
  'update-fail-default-desc': 'Update failed. Please try again.',
  'invalid-recipient-argument-desc': 'Update failed. Data is invalid.',
  'noMoreItems': 'No more Items...',
  'noMoreTransactions': 'No more transactions',
  'choose-payment-label': 'Select your payment method',
  'payment-group-card': 'Pay by credit card',
  'payment-new-card': 'New credit card',
  'payment-choose-card': 'Manage',
  'keepShopping': 'Keep Shopping',
  'no-support': 'This item does not support',
  'cod-both': 'Home / CVS COD',
  'cod-home': 'Home COD',
  'hasCOD': 'COD',
  'hasCVS': 'CVS',
  'noCVS': 'no support CVS',
  'subtotal': 'subtotal',
  'paymentFee': 'payment fee',
  'shippingFee': 'shipping fee',
  'internalShippingFee': 'internal shipping fee',
  'crossBorderShippingFee': 'cross border shipping fee',
  'shippingTracking': 'Shipping Tracking',
  'goToQuery': 'Go To Query',
  'vcoin': 'vcoin',
  'point': 'point',
  'vcoin-bill-detail': 'vcoin',
  'event-vcoin-bill-detail': 'event vcoin',
  'storage-vcoin-bill-detail': 'storage vcoin',
  'coupon': 'coupon',
  'coupon-discount': 'coupon',
  'coupon-discount-label': 'Discount $%{value}',
  'coupon-bill-detail': 'coupon',
  'use-coupon-code': 'Use coupon',
  'totalPrice': 'total price',
  'total-payment-amount': 'Total payment amount',
  'coupon-cancel-bar-label': 'Select 1 coupon，discount',
  'dollar': 'dollar',
  'coupon-cancel-bar-cancel': 'Cancel',
  'redeem': 'Redeem',
  'cancel-redeem': 'Cancel',
  'coupon-select': 'Select coupon',
  'coupon-placeholder': 'Please type your redeem code',
  'coupon-default-fail': 'Oops! Please try again.',
  'coupon-default-success': 'Success！Discount $%{value}',
  'no-coupon': 'You do not have any coupon',
  'recently-no-coupon': 'You recently do not have any coupon',
  'coupon-error-not-found': 'Coupon not found',
  'cart-empty': 'Your cart is empty',
  'confirm-remove-cart-item': 'Are you sure to delete?',
  'coupon-error-expired': 'Sorry, the coupon has expired',
  'coupon-error-used': 'The coupon is used',
  'coupon-error-redeemed': 'The coupon is redeemed',
  'coupon-error-else': 'This coupon code cannot be used',
  'coupon-detail': 'Detail',
  'coupon-label-expiring': 'Expiring',
  'coupon-label-under-threshold': 'Under Threshold',
  'notification-empty-login': 'Login to see notifications',
  'notification-empty': 'You have got noting',
  'coupon-label-new': 'New',
  'coupon-tab-available': 'Available',
  'coupon-tab-used': 'Used',
  'coupon-tab-expired': 'Expired',
  'used-in': 'Used in',
  'use-period': 'Use period to',
  'no-use-period': 'No use period',
  'couponRedeemed': 'Coupon redeem success\nPress "finish" to apply',
  'rating-text': {
    '0': 'NotReviewed',
    '1': 'Very dissatisfied',
    '2': 'Dissatisfied',
    '3': 'OK',
    '4': 'Satisfied',
    '5': 'Very satisfied',
  },
  'success-rating': 'Success',
  'SearchNotFoundTitle': 'Sorry! We cannot find anything.',
  'SearchNotFoundSubTitle': ' try another keywords!',
  'SearchNotFoundHint': 'Try a different keyword!',
  'filter-not-found-subtitle': 'Please try other filter conditions.',
  'orderDetail-delivery-date': 'Estimated shipping date',
  'orderDetail-status': {
    [TransactionStatus.Unpaid]: 'Unpaid',
    [TransactionStatus.Paid]: 'Paid',
    [TransactionStatus.Stocking]: 'Processing',
    [TransactionStatus.Sent]: 'Sent',
    [TransactionStatus.Expired]: 'Expired',
  },
  'orderSuccess': 'Success Order',
  'orderDetail-delivery-info': 'Latest shipping date',
  'orderDetail-transaction-no': 'Order no',
  'orderDetail-created-at': 'Created at',
  'orderDetail-logistics-provider': 'Logistics provider',
  'orderDetail-local-ship-no': 'Ship No.',
  'orderDetail-more': 'more...',
  'orderDetail-credit-success': 'Successful payment',
  'orderDetail-credit-failure': 'Payment failure! %{msg}',
  'orderDetail-payment-text': 'Payment',
  'orderDetail-payment-limit-hint': 'Please, Complete payment within the deadline',
  'orderDetail-this-order-only': 'Only for this order',
  'orderDetail-total-price': 'total price',
  'orderDetail-payment-limit': 'payment limit',
  'orderDetail-paid-at': 'paid at',
  'orderDetail-total-quantity': 'Quantities: %{quantity}',
  'orderDetail-bank-name': 'bank name',
  'orderDetail-account-name': 'account name',
  'orderDetail-account-number': 'account number',
  'orderDetail-cvs-code': 'cvs code',
  'receiptID': 'Receipt ID',
  'receiptCreateAt': 'Create at',
  'orderDetail-retry-atm': 'Pay by ATM',
  'orderDetail-retry-card': 'Retry',
  'orderDetail-retry-line-pay': 'Retry by LINE Pay',
  'orderDetail-retry-apple-pay': 'Retry by Apple Pay',
  'orderDetail-use-card': 'Use Credit Card',
  'orderDetail-account-refreshing': 'Generating account number...（%{sec}）',
  'orderDetail-account-failed': 'Fail to generate account number, please scroll down to refresh.',
  'transaction-date': 'Order date',
  'oridnal-item': 'Item %{oridnal}',
  'rate': 'Rating',
  'view-rate': 'View Rating',
  'rate-transaction': 'Rate the Transaction',
  'rate-transaction-submit-button': 'Submit',
  'has-done-rate': 'Already Rated',
  'rate-leave-rating-dialog-msg': 'If you leave now, the rating will not be saved. Are you sure to leave?',
  'rate-continue-rating': 'Continue Rating',
  'rate-break-rating': 'Leave',
  'review-deadline-text': 'Review before %{reviewDeadline}',
  'review-vcoin-text': 'To get %{vcoin} vcoins',
  'item-reviews-title': 'Reviews',
  'item-combo-title': 'Try this combination',
  'sku-size': 'Size:',
  'sku-color': 'Color/Category:',
  'recommend-combo': 'Recommend Combo',
  'total-combo-items': 'Selected %{totalQuantity} items',
  'item-total-reviews': '%{total} reviews',
  'end-of-reviews': 'End of reviews',
  'feedback': 'Feedback',
  'eventPage': 'Get Rewards',
  'vcoinPage': 'My VCoins',
  'mailboxPage': 'Mailbox',
  'refundPage': 'Return Application',
  'supportPage': 'Contact Service',
  'shareFriendPage': 'Invite friends to earn vcoins',
  'transactionDetailPage': 'Transaction Detail',
  'notifications-page': 'Notifications',
  'notifications-page-turn-on-noti-guide': {
    'dialog-text': '<text1>You have unread messages</text1><text2>Turn on notifications immediately, never miss a message</text2>',
    'noti-bar-for-unread': "<b>Turn on notifications now</b> so you don't miss any important messages!",
    'noti-bar--title-for-read': 'You have read all notifications',
    'noti-bar--content-for-read': "It's recommended to turn on notifications to keep abreast of the latest news",
  },
  'onsale-page': 'OnSale',
  'serviceTime': 'Monday to Friday 10:00-19:00 (Holiday break)',
  'contactToService': 'Contact to service',
  'faqPage': 'Help',
  'itemDetail-out-of-stock': 'out of stock',
  'itemDetail-is-not-allowed-hint': 'item is not allowed',
  'itemDetail-is-not-allowed-text': 'Due to the law/size/safe/etc. reason, this item is not allowed to purchase in your area',
  'itemRecommend': 'You may also like',
  'itemEnd': 'Want more?',
  'backToHomePage': 'Keep shopping',
  'go-to-home-page': 'Go to Home Page',
  'back-to-home-page': 'Home',
  'special-for-you': 'Special for you',
  'pick-for-you': 'Pick for you',
  'itemDetail': 'Item Detail',
  'recommend': 'Recommend',
  'show-more': 'More',
  'show-products': 'Show %{count} products',
  'recommend-cat': 'Recommended categories',
  'search-discovery': 'Discovery',
  'free-delivery': 'Free shipping',
  'default-event': 'Sale campaign',
  'free-deliver': 'Free deliver',
  'price-matching': 'Offer',
  'news': 'News',
  'empty-transaction-list': "you wasn't have any order",
  'empty-browse-history-list': "You wasn't have any browse history",
  'go-receive': 'Go receive',
  'go-to-order': 'Go shopping',
  'go-shopping': 'Go shopping',
  'goShopping': 'Go shopping',
  'go-to-other-places': 'Go to other places',
  'empty-product-list': 'There are no items',
  'delivery-info': 'days to ship',
  'no-more-onboarding-item': 'No more items',
  'receiver': 'Receiver',
  'recipientAddress': 'Recipient address',
  'convenienceStoreName': 'Convenience store name',
  'coupon-guide-step1-title': 'Never use coupon?',
  'coupon-guide-step1-content': 'You can redeem coupon at 「%{eventPage}」 (Note: Coupon will expire, remember to use it!!)',
  'coupon-guide-step1-button': 'But how?',
  'coupon-guide-step2-title': 'Coupon discount',
  'coupon-guide-step2-content': 'While checkout, press 「%{couponDiscount}」 and then select one, after that press 「%{finish}」to discount!',
  'verify-quit-confirm': 'Are you sure you want to quit verification?',
  'verify-quit': 'Quit',
  'verify-quit-continue': 'Continue',
  'coupon-guide-step2-button1': 'Redeem now, GO!',
  'coupon-guide-step2-button2': 'Okay, I got it',
  'chooseCreditCard': 'Manage Credit Card',
  'shippingLogistics': 'Shipping',
  'redeem-right-now': 'Click',
  'already-redeemed': 'Redeemed',
  'coupon-not-available': 'Not Available',
  'submit': 'submit',
  'confirmToCancelCreditCardPayment': 'Are you sure to cancel credit card payment?',
  'continuePaying': 'Continue paying',
  'putPrimaryCardErrorDefaultDesc': "Sorry! It didn't work. Please try again.",
  'putPrimaryCardErrorInvalidArgument': 'This Credit Card is invalid, Please check again.',
  'maximum-item': 'Maximum item',
  'added-success': 'Added success',
  'check-cart': 'Check cart',
  'have-yet-to-choose': 'Have yet to choice',
  'buy-it': 'Buy it',
  'add-to-cart': 'Add to cart',
  'out-of-stock': 'Out of stock',
  'amount': 'amount',
  'retry': 'Try again',
  'my-page-banner-text-guest': 'APP orders over a certain amount will receive 10%',
  'my-page-banner-text-guest-gc': 'Download the APP for better shopping experience!',
  'my-page-banner-text-member': 'Download the APP for better shopping experience!',
  'my-page-banner-button': 'Download APP',
  'open-the-app': 'Open the APP',
  'item-page-app-download-default-title': 'Enjoy extra discounts when ordering via APP',
  'item-page-app-download-default-title-gc': 'Enjoy a smoother shopping experience with the APP',
  'item-page-app-download-default-text': 'APP orders over a certain amount will receive 10% off (the more you buy, the more you get)',
  'item-page-app-download-default-text-gc': 'Download now!',
  'sku': 'color / size',
  'review-item-sku': 'SKU: %{sku}',
  'list-page-title': 'Classic %{cat} Series Recommendations',
  'review-section-cta': 'All Reviews',
  'review-section-empty': 'No review',
  'select-style': 'Select',
  'select-discount': 'Select Discount',
  'select-discount-hint': 'Use only one offer, offer may not be combined.',
  'class': 'Category',
  'class-title': 'Category',
  'class-desc': 'With over 20 clothing categories, including tops, pants, jackets, dresses, gowns, bags, accessories, kids wear, swimwear, shoes, and more, buy the entire outfit in one go!',
  'new-arrivals-share-message': 'New goods arrived every week, come check it out!',
  'on-sale-share-message': "It's on sale everyday!",
  'item-share-message': 'You see this product is very good, share it with you',
  'campaign-share-message': "Take a look at VeryBuy's new campaign this month, there are many discounts：",
  'default-share-message': 'Wow! the products here look good. Share with you, and go shopping together!',
  'press-again-to-exit-app': 'Press again to exit app.',
  'notification-channel-default': 'Default notification',
  'cvs-pick-up-goods-hint': 'When picking up the goods, please remember to bring your ID card and let the store staff check your identity and get the goods smoothly!',
  'provide-name-for-shop-hint': 'Please provide real name based on ID card to the shopper, then get your orders.',
  'choose-receiving-shop': 'Choose receiving shop',
  'change-receiving-shop': 'Change receiving shop',
  'delivery-method-home': 'Home delivery',
  'delivery-method-home-cod': 'Home delivery (COD)',
  'delivery-method-csv': '7-11 pickup',
  'delivery-method-csv-cod': '7-11 pickup (COD)',
  'home-delivery': 'Home delivery (COD available)',
  'home-delivery-for-gc': 'Home delivery',
  'convenience-store-pickup': '7-11 pickup',
  'shipping-error-title': "Can't use convenience store pickup:",
  'shipping-cov-error-title': "Can't use the COD pickup:",
  'go-paying': 'Go paying',
  'edit-home-delivery-info': 'Edit home delivery info',
  'create-home-delivery-info': 'Create home delivery info',
  'edit-cvs-delivery-info': 'Edit store delivery info',
  'create-cvs-delivery-info': 'Create store delivery info',
  'select-shipping-method': 'Select shipping method',
  'shipping-method-with-symbol': 'Shipping method:',
  'receipt-type-with-symbol': 'Receipt type:',
  'receipt-type': 'Receipt type',
  'receipt-note': 'Please note: Once the invoice type is selected, it cannot be changed later',
  'receipt-desc': 'When handling returns and refunds, VeryBuy will process invoices and sales return certificates on your behalf to speed up the refund process.',
  'duplicate-uniform-receipt': 'Duplicate uniform receipt',
  'triplicate-uniform-receipt': 'Triplicate uniform receipt',
  'copy': 'Copy',
  'copied-your-ship-no': 'Copied your ship No.',
  'my-order': 'My Order',
  'my-coupon': 'My Coupon',
  'my-collection': 'My Collection',
  'collection': 'Collection',
  'my-browse-history': 'My Browse History',
  'already-collection': 'Collected',
  'not-yet-collection': 'Collect',
  'browse-history': 'Browse History',
  'collection-successful': 'Collection Successful',
  'collection-failed': 'Collection Failed',
  'collection-canceled': 'Collection Canceled',
  'collection-removed': 'Collection Removed',
  'collection-removed-failed': 'Collection Removed Failed',
  'collection-cancellation-failed': 'Collection Cancellation Failed',
  'collection-full': 'Your collection is full.\nPlease clean up.',
  'collection-offline': 'Sold out items cannot be collected',
  'cart-full': 'Your cart is full.\nPlease clean up.',
  'sold-out': 'Sold Out',
  'is-not-allowed': 'Not Allowed',
  'deprecated-first-love-dialog-content': 'You can look up collected products in "My Page".',
  'first-love-dialog-content': 'You can look up collected products and vendors in "My Page".',
  'not-yet-collect-hang-around': "You haven't collected anything yet, starting to hang around.",
  'not-yet-collect-vendor': "You haven't collect any vendor.",
  'payment': 'Payment',
  'delivery': 'Delivery',
  'cancel-order': 'Cancel',
  'no-receipt-required': 'No Receipt Required',
  'no-payment-required': 'No Payment Required',
  'cancel-order-dialog-title': 'Are you sure you want to cancel your order?',
  'cancel-order-dialog-title-failed': 'Cancel order failed',
  'cancel-order-dialog-content': 'After the order is cancelled, the original discount code cannot be reserved, and the discounted vcoin will be returned to you.\nIf you buy again, we recommend that you place a new order.',
  'confirm-submit-order': 'Confirm Payment',
  'confirm-cancel': 'Confirm Cancel',
  'confirm-select': 'Confirm',
  'give-up-cancel': 'Give Up Cancel',
  'got-it': 'I Got It',
  'block-message': "Sorry, you've violate the restrictions,\nthe service is currently disabled.",
  'look-up-logistics': 'Look up logistics',
  'product-details': 'Product Details',
  'separate-shipment': 'Separate Shipment',
  'unshipped': 'Unshipped',
  'number': NUMBER_FORMAT,
  'separated-shipment-hint': 'This order adopted separated shipment.',
  'search-form-desc': 'Enter product keywords\nSearch for the very good things you want.',
  'recent-search': 'Recent Search',
  'hot-search': 'Hot Search',
  'hot-categories': 'Hot Categories',
  'recommend-subjects': 'Recommend Subjects',
  'clear-histories': 'Clear Histories',
  'clear-stockout': 'Clear',
  'clear-stockout-dialog-content': 'Are you sure to clear all the out of stock items?',
  'clear-stockout-dialog-positive': 'Sure',
  'clear-stockout-dialog-negative': 'No',
  'request-position-permission-title': "When you're choosing convenience store, do you allow to access your location?",
  'request-position-permission-content': 'Allowing access to your location can quickly find out the convenience store nearby you.',
  'allow': 'Allow',
  'dont-allow': "Don't allow",
  'top-sales': 'Top sales',
  'top-rated': 'Top rated',
  'top-rated-og-desc': 'Looking for fashion items with high CP value? Many netizens have unanimously praised it. You can definitely wear it. VeryBuy is your best dressing assistant!',
  'top-rated-desc': `User's top rated items in last 7 days`,
  'sales-charts': 'Sales Charts',
  'sales-charts-desc': 'The most popular choice nowadays, a fashion trend that you must not miss, go to VeryBuy now to get the latest fashion trends!',
  'browse-similar-items': `browse Vendor's other items`,
  'pull-to-anchor-more-product': 'More',
  'release-to-anchor-more-product': 'Release to anchor',
  'watch-more-product': 'More',
  'image-detail': 'Detail',
  'watch-more': 'more',
  'watch-more-discount': 'more discount＞',
  'new-arrivals': 'New Arrivals',
  'new-arrivals-desc': "Are you still worried about what to wear? Thousands of women's clothing, dresses, tops, bottoms and other clothing, come and explore VeryBuy's latest fashion products, taking you to the forefront of fashion!",
  'shopping-now': 'Shopping now',
  'shop-more': 'more',
  'theme-shopping-default-title': 'Hello,\nHow do you want to wear today?',
  'theme-detail-daily-news-title': 'Daily Updated',
  'theme-sale-list-title': 'Hot Sales',
  'not-found-page': 'On! This page no longer exists.',
  'not-found-page-app': 'On! This page no longer exists.\nPerhaps update the app and try again.',
  'go-back-to-prev-page': 'Go back to prev page.',
  'go-back': 'Go back.',
  'finish': 'Finish',
  'checkout-step-1': 'Check Order (1/2)',
  'checkout-step-2': 'Check Order (2/2)',
  'no-more-personal-recommend': 'No more personal recommend',
  'no-more-theme-shopping-item': 'No more Items, It it not enough.',
  'shopping-other-categories': 'Shopping Other Categories',
  'no-more-top-sales': 'No more Items...',
  'found-new-version-title': 'Get the latest version',
  'found-new-version-content': 'Looks like you’re using an older version of VeryBuy. Update now for less bugs, better performance, and the latest features.',
  'update-now': 'Update now',
  'price-postfix': 'up',
  'createCreditCard': 'Create Credit Card',
  'period-task-success': 'Success',
  'period-task-fail': {
    'email-verify': 'Please verify your email and phone',
    'unqualified': 'You may not execute this action',
    'already-redeemed': 'You have already done this before',
    'expired': 'This has been expired, try earlier next time, good luck!',
  },
  'last-login-method': {
    [LoginOrRegisterMethod.EMAIL]: 'Last login: Email login',
    [LoginOrRegisterMethod.APPLE]: 'Last login: Apple login',
    [LoginOrRegisterMethod.FACEBOOK]: 'Last login: Facebook login',
    [LoginOrRegisterMethod.LINE]: 'Last login: LINE login',
  },
  'onboarding-redeem-coupon-success': 'Success',
  'redeem-coupon-fail': {
    'redeemed': 'The coupon is redeemed',
    'expired': 'Sorry! The coupon have been issued',
    'is-over': 'Sorry! The coupon have been issued',
  },
  'transaction-promotions': {
    'title-for-web': 'Download VeryBuy',
    'title-for-app': 'Turn on push notification',
    'content-for-web': "Hush! Don't say I didn't tell you... It is more convenient to use the App to track orders and check the status of goods!",
    'content-for-app': 'When will the goods be received? Turn on the push notification, and the shipment will notify you immediately!',
  },
  'login-to-redeem': 'Login',
  'get-coupon-dialog': {
    'title-for-app': "Don't let the coupons expire",
    'content-for-app': 'Coupon has been put in "My Coupon" for you. Do you want to open the notification of the coupon expiration?',
    'positive-text-for-app': 'Turn on notifications',
    'negative-text': 'Talk about it next time',
  },
  'request-noti-permission-alert': {
    'title': 'Not yet open notifications',
    'content-for-transaction': 'You do not have the notification permission enabled, and you will not be notified immediately when the order is shipped or delivered. Please click "Settings"> "Notifications"> Turn on "Allow Notifications"',
    'content-for-coupon': ' You do not have the notification permission enabled, and you will not be notified immediately when the coupon is about to expire. Please click "Settings"> "Notifications"> Turn on "Allow Notifications"',
    'positive-text': 'Go to settings',
    'negative-text': 'No, thanks',
  },
  'mail-detail-transaction-title': {
    [SupportType.PaymentIssue]: 'Payment problem',
    [SupportType.DeliveryIssue]: 'Delivery problem',
    [SupportType.ReturnApplicationNotice]: 'Return request notice',
    [SupportType.OrderIssue]: 'Order problem',
    [SupportType.DelayNotice]: 'Delayed notice',
    [SupportType.OutOfStockNotice]: 'Out of stock notice',
  },
  'mail-detail-page-title': {
    [SupportType.PaymentIssue]: 'Payment',
    [SupportType.DeliveryIssue]: 'Delivery',
    [SupportType.ReturnIssue]: 'Return',
    [SupportType.VCoin]: 'Vcoin / Coupon',
    [SupportType.Other]: 'Others',
    [SupportType.VEditorNotice]: 'Verybuy message',
    [SupportType.CashRefundNotice]: 'Refund cash',
    [SupportType.RefundNotice]: 'Refund',
    [SupportType.ReturnApplicationNotice]: 'Return',
    [SupportType.CreditCardCancelNotice]: 'Refund credit card',
    [SupportType.CreditCardIncompleteNotice]: 'Payment failed',
    [SupportType.ElectronicInvoiceNotice]: 'Invoice',
    [SupportType.ProductInformation]: 'Product info',
    [SupportType.Activity]: 'Event',
    [SupportType.OrderIssue]: 'Order',
    [SupportType.MemberIssue]: 'Member',
    [SupportType.DelayNotice]: 'Delivery delay',
    [SupportType.OutOfStockNotice]: 'Out of stock',
  },
  'mailbox-list-title': {
    [MailType.Mail]: 'Personal mail',
    [MailType.Notification]: 'Official mail',
  },
  'mailbox-detail-quit-confirm': 'Leaving now will not keep your input\nAre you sure you want to leave to discard editing?',
  'transaction-list-title': {
    'all': 'All',
    [TransactionStatus.Unpaid]: 'Unpaid',
    [TransactionStatus.Paid]: 'Paid',
    [TransactionStatus.Stocking]: 'Stocking',
    [TransactionStatus.Sent]: 'Delivered',
    [TransactionStatus.Expired]: 'Cancelled',
  },
  'transaction-no': 'Transaction ID: %{transactionNo}',
  'check-transaction': 'Check',
  'go-to-see': 'Go to see',
  'get-coupon-toast': 'Get coupon successfully.\nHas already put into My Coupon.',
  'download-app-button-content': 'It is more convenient to use the App to query orders!',
  'create-credit-card': 'Create Credit Card',
  'credit-card-number': 'Credit Card Number',
  'accepted-credit-card': 'Accepted credit card/account card',
  'input-correct-card': 'Please input correct card.',
  'input-correct-expiration-date': 'Please input correct expiration date.',
  'input-correct-cvc': 'Please input correct cvv.',
  'member-delete': 'Delete Account',
  'member-delete-info-html': '<p>1. Please confirm if there are unfinished <a href="%{orderHref}">orders</a> in your account. Please notice if you delete your account, you will not be able to check the delivery status, and also you will not be able to provide after-sales service online.<br><br>2. After deleting your account, you will lose all unused <a href="%{vcoinHref}">vcoin</a> and <a href="%{couponHref}">coupons</a> in your account, which cannot be recovered.<br><br>3. After completing the deletion of the account, we will retain the transaction data and use it for financial auditing purposes only.<br><br>4. Once you have finished deleting your account, you will not be able to log in and view your previous order history.<br><br>5. Verybuy will reserve to refuse the user\'s repeated application request to create a new account in the future.</p>',
  'member-delete-first-title': 'Important',
  'member-delete-first-subtitle': 'Before deleting, you need to know:',
  'member-delete-confirm-label': 'I have confirmed and agree to the above',
  'next-step': 'Next',
  'member-delete-second-title': 'We appreciate your suggestion',
  'member-delete-second-subtitle': 'Please tell us how can we do better',
  'member-delete-selection-toast': 'Maximum three selection',
  'member-delete-q1-title': 'What feature or service would you most like to improve?',
  'member-delete-q1-subtitle': 'Required, multiple (up to three)',
  'member-delete-q2-title': 'Any other advice you?',
  'member-delete-q2-subtitle': 'Not required (word restriction is %{maxLength})',
  'member-delete-q2-placeholder': 'say something',
  'member-delete-select': {
    '0': 'Discount',
    '1': 'Web / APP fluency of use',
    '2': 'Accuracy of product search',
    '3': 'Clearer information',
    '4': 'Quality of product',
    '5': 'Delivery speed',
    '6': 'After-sales service',
    '7': 'Customer service',
  },
  'member-delete-third-title': 'Do you delete the account？',
  'member-delete-third-subtitle': 'You could not use VeryBuy service after deleting the account.',
  'member-delete-button': 'Delete',
  'member-deleted-message': {
    'title': 'Your account is deleted',
    'content': 'Sadly, you are away 🥲\nWe hope serve you in the future',
  },
  'line-login-duplicated-email-dialog': {
    'content': 'We have found an account with the same verification email for you, and it has been bound to the same account for you. You can use LINE or E-mail to log in to the member faster',
  },
  'save-credit-card': 'Save credit card',
  'save-credit-card-create-error': 'Verification fail,\nplease check and try again.',
  'credit-card-info': "While creating new credit card, VeryBuy will process a NT$1 test order. This order won't be charged actually. Please follow the steps with no worries.",
  'delete': 'delete',
  'confirm-delete-card': 'Sure to delete the card ?',
  'create-credit-card-slogan': 'Create Credit Card To Make Checkout Faster',
  'create-credit-card-guide-title': 'Manage credit cards independently',
  'create-credit-card-guide-content': 'Encrypted and commonly used credit cards make it more convenient for you to checkout. You can also click the "trash" next to the card number to delete the card at any time, do not worry!',
  'create-receipt-info': 'Create Receipt Info',
  'edit-receipt-info': 'Edit Receipt Info',
  'receipt-info': 'Receipt Info',
  'buyer-identifier': 'Buyer Identifier',
  'buyer-name': 'Buyer Name',
  'buyer-identifier-placeholder': 'Please input buyer identifier 8 digits.',
  'buyer-name-placeholder': 'Please input buyer name.',
  'invalid-buyer-identifier': 'Please input buyer identifier 8 digits.',
  'invalid-buyer-name': 'Invalid format, special characters only support: ,-_()/',
  'invalid-name-length': 'The max length of name is 255 characters',
  'zendesk-unread-msg': 'You have %{count} unread message.',
  'recommand-item-title': 'Recommend good things.',
  'all-categories': 'All categories',
  'you-may-also-like': 'You may also like',
  'service-policy': 'Service Policy',
  'terms-service': 'Terms service',
  'anti-fraud': 'Anti fraud',
  'about-verybuy': 'About VeryBuy',
  'aboutus': 'About us',
  'recruiting': 'Recruiting',
  'manufacturer-cooperation': 'Manufacturer cooperation application.',
  'latest': 'latest',
  'my-customer-service': 'My customer service',
  'weekly-hot': 'Weekly Hot',
  'relevance': 'Recommend',
  'vb-wholesale': 'Wholesale service',
  'fast-delivery': 'Fast delivery',
  'fast-delivery-text': 'Fast delivery',
  'burger-item-categories': 'All categories',
  'sale': 'Sale',
  'categories-index': 'Categories',
  'check-origin-link': 'Check original item link',
  'checking-profile': 'Go ahead to check my Profile',
  'customer-service': 'help',
  'cs-title': 'Talk',
  'tutorial-question-title': 'How many practical skills do you know?',
  'tutorial-question-content': "Don't say I didn't tell you, how can you not know such a useful function?",
  'tutorial-question-button-cancel': 'Cancel',
  'tutorial-question-button-tell-me': 'Tell me',
  'item-header-tutorial-title': 'The most commonly used functions are at your fingertips',
  'item-header-tutorial-app-content': 'Sharing, collection, and direct functions are all here, providing you with a more convenient shopping experience.',
  'item-header-tutorial-content': 'More product classification, sharing, collection, and direct functions are all here, providing you with a more convenient shopping experience.',
  'zoom-in-image-tutorial-title': 'Click to slide to enlarge the product.',
  'zoom-in-image-tutorial-content': 'Click on the product image and slide to zoom in and out to see more clearly!',
  'home-theme-shopping-tutorial': {
    'title': 'Choose the style you like',
    'content': "Don't worry about what you want to wear anymore, come and see what you can do for these occasions!",
    'positive-btn-text': 'Start shopping',
  },
  'theme-shopping-detail-tutorial': {
    'title': "Update everyday, don't miss your wearing inspiration.",
    'content': 'Follow your interested theme which brings you more wearing possibilities.',
    'positive-btn-text': 'Discover',
  },
  'notification-dialog': {
    'transaction-title': 'Get first-hand notification of your order now!',
    'transaction-content': 'Want to be the first to know that the package has shipped? Turn on push notifications immediately, and messages will not be missed!',
    'negative-btn-text': 'Let me think about it',
    'negative-btn-text2': 'Next time',
    'positive-btn-text': 'Turn on Notifications',
  },
  'cart-coupon-hint': 'Coupon and discount codes can be used after clicking "Checkout"',
  'pass': 'Pass',
  'next': 'Next',
  'watch-now': 'Try Now',
  'welcome-announcement-title': 'Celebrate for VeryBuy website revision!',
  'welcome-announcement-content': 'The new operation interface of VeryBuy brings you the better user experience',
  'experience-now': 'Experience now',
  'all': 'all',
  'member-edit': 'Member Edit',
  'home-banner-title': "What's new",
  'home-category-title': 'Want more',
  'general-web-header-hint': {
    'title': 'The function column below is moved here!',
    'content': 'Check the shopping cart, order, browse the categories of the whole site!',
  },
  'vcoin-to-cash': 'vcoin to cash',
  'line-pay-payment-failure': 'LINE Pay payment failed, please confirm LINE application has been installed or use other payment methods',
  'apple-pay-payment-failure': 'Apple Pay payment failed, please confirm Apple Pay has been setup or use other payment methods',
  'notification-tooltip': 'Your orders update notification is here!',
  'filter-notification-tooltip': 'Using filter to find your favourite',
  'customer-service-hint': 'Hi, how may I help you?',
  'member-identity-verify': 'Member Identity Verify',
  'phone-verify-info': 'In order to avoid your transaction identity stolen by bad person, before COD, please bind your phone number, after verification success, representing you agree with VeryBuy',
  'get-verify-code': 'Get verify code',
  'your-phone-number': 'Your phone number',
  'no-coupon-can-get': 'No coupon can get now.',
  'limited-get-coupon': 'Limited get coupon',
  'select-city-label': 'City',
  'category-tags-title': 'Theme Finding Inspiration',
  'show-more-tags': 'Show more tags',
  'show-fewer-tags': 'Show fewer tags',
  'paymentMethods': {
    'title': 'Payment methods',
    [PaymentMethods.CREDIT_CARD]: 'Credit card',
    [PaymentMethods.COD]: 'COD',
    [PaymentMethods.CVS]: 'CVS',
    [PaymentMethods.ATM]: 'ATM',
    [PaymentMethods.LINE_PAY]: 'LINE Pay',
    [PaymentMethods.APPLE_PAY]: 'Apple Pay',
  },
  'deliveryMethods': {
    'title': 'Delivery methods',
    [DeliveryMethods.HOME]: 'Home (COD available)',
    [`${DeliveryMethods.HOME}-for-gc`]: 'Home',
    [DeliveryMethods.CVS]: '7-11',
  },
  'delivery-modal-description-1': '※ The actual internal shipping fee is subject to the checkout page and may incur additional charges due to the delivery location and payment method.',
  'delivery-modal-description-2': '※ Internal free shipping offer is applicable after deducting promotional discounts (such as coupons, loyalty points, etc.) from the subtotal of the products, and reaching the threshold.',
  'support-transaction-id-picker-title': 'Select order number',
  'support-faq-question-type': {
    'title': 'How can we help you?',
    'select-default-text': 'Question type',
  },
  'support-faq-section-title': 'Maybe you want to ask',
  'support-faq-page-contact-us': 'Did it solve your problem?\n if there are other questions',
  'shipping-logistics-login-require': {
    'title': 'To protect your data',
    'description': 'Log in to view "Logistics Details"',
  },
  'support-loading-error-text': 'The system is busy right now, please retry later ...',
  'support-submit-success': 'Message sent successfully!\nA customer service specialist will reply within 1-3 working days\nThanks for your patience!',
  'support-submit-success-noti': 'Message successfully sent!\nSpecialist will reply within 1-3 working days,\nenable the notification to receive our response',
  'shipping-logistics-app-download': {
    'title': 'Open VeryBuy Fashion',
    'description': 'Unlock "Logistics Tracking" function!',
    'unlock-now': 'Unlock now',
    'modal-title': 'Logistics tracking function schematic',
    'modal-banner-description': 'Unlock "Logistics Tracking" function now!',
    'modal-unlock-now': 'Unlock',
  },
  'recent-transaction-no-data-option': 'No purchase information yet',
  'add-mail-textarea-placeholder': 'Please provide a description of the situation you encountered...',
  'city': 'City',
  'city-and-district': 'Counties, cities and towns',
  'road': 'Road',
  'store': 'Store',
  'describe': 'Describe',
  'promotions': 'Promotions',
  'promotions-modal': 'Promotions',
  'select-city-and-district': 'Select counties, cities and towns',
  'display-all': 'See More',
  'select-road': 'Select road',
  'select-store': 'Select store',
  'member-settings': 'Settings',
  'notification': 'Offer and Shipment Progress Notification',
  'notification-enabled': 'enabled',
  'notification-disabled': 'disabled',
  'notification-remind': 'It is recommended to turn on notifications to receive real-time offers and delivery status!',
  'best-seller-share-msg': 'Take a look at these fantastic products',
  'ratings': 'Rate VeryBuy APP',
  'language': 'Language',
  'check-my-coupons': 'Check My Coupons＞',
  'free-delivery-text': 'Buy <money>NT$%{money}</money> more to <bold>enjoy free delivery</bold>!',
  'free-delivery-text-without-trans': 'Buy NT$%{money} more to enjoy free delivery!',
  'tw-free-delivery-text': 'Buy <money>NT$%{money}</money> more to <bold>enjoy internal free delivery</bold>!',
  'tw-free-delivery-text-without-trans': 'Buy NT$%{money} more to enjoy internal free delivery!',
  'coupon-threshold-text': 'Buy <money>NT$%{couponDiff}</money> more to use %{couponAmount} coupon',
  'reach-free-delivery-threshold': 'The free shipping threshold has been reached.',
  'reach-coupon-threshold': 'The $%{couponAmount} coupon threshold has been reached',
  'sales-promises': {
    'name': 'Appreciation Period Promises',
    'cooling-off-period-title': '14 Days Cooling-off Period',
    'cooling-off-period-desc': 'VeryBuy offers a 14 days cooling-off period for shopping with confidence! Users can apply for return within 14 days after receiving the goods, without paying extra overseas return fees, please refer to the official website announcement for <a>return instructions</a> for details',
    'late-delivery-title': 'Late Delivery Compensation',
    'late-delivery-desc': 'VeryBuy promises to ship within the shipping time displayed on the product page (except for force majeure factors such as epidemics and natural disasters), if the time is exceeded, a delay compensation will be issued',
  },
  'daily-bonus': 'Daily Gift',
  'coupons-collection-area': 'Coupons',
  'message-bar-placeholder': 'send messages...',
  'cannot-reply-text': 'Is there anything else we can do for you?',
  'service-link-text': 'Contact again',
  'submit-message': 'Submit',
  'message-reminder': '※ This feature is not real-time communication, customer service response time is about 1~3 working days, thank you for your patience.',
  'add-mail-reminder': '※ Customer service online time: Monday to Friday, 10:00~19:00 (except national holidays), the response time is about 1~3 working days',
  'photos-number-limit': 'The number has reached the limit',
  'message-bad-network-connection': 'Oh no! The network is currently experiencing turbulence.\n The message was not successfully sent out, please try again.',
  'resend': 'resend',
  'no-current-mails': "You haven't had any mail in the last three months.",
  'contact-us': 'Contact Us',
  'upload-image': 'Upload image',
  'image-exceed-limit': 'image exceeding %{size}MB upload limit, please re-upload',
  'event-page-download-modal-hint': 'APP order, get 10% off for every $1500',
  'event-page-download-modal-button': 'Download Now',
  'event-page-download-banner-button': 'Download',
  'event-page-download-banner-title': 'VeryBuy Fashion',
  'event-page-download-banner-text': 'Place an order through the APP, and get a 10% discount for orders over $1500',
  'color-amount-tag': '%{amount} styles',
  'single-color-amount-tag': 'single style',
  'turn-on-notifications': 'Turn on Notifications',
  'webpush-subscribe-modal-positive': 'Allow',
  'webpush-subscribe-modal-negtive': 'Later',
  'webpush-subscribe-modal-title': 'Subcribe our notification',
  'webpush-subscribe-modal-content': 'VeryBuy Fashion provide fashionable products and services to you, please subscribe to our notification to receive timely updates.',
  'view-orders': 'View Orders',
  'select-all': 'Select All',
  'checkout': 'Checkout',
  'go-checkout': 'Go Checkout',
  'delivery-name': 'Name',
  'country-code': 'Country Code',
  'area-phone': 'Phone Number',
  'delivery-country': 'Country',
  'delivery-country-region': 'Country / Region',
  'delivery-address': 'Address',
  'delivery-address-for-gc': 'Address',
  'delivery-address-placeholder': 'street, apt., suite, building access code, etc.',
  'delivery-city-placeholder': 'Please enter your city',
  'delivery-zip-code-placeholder': 'Please enter a valid zip code',
  'delivery-zip-code-placeholder-for-hk': 'Please enter a valid zip code. If none, please enter 000',
  'zip-code': 'Zip / postcode',
  'district-picker-title': 'Select City and District',
  'third-party-change-password-ignore-text': 'This account uses <method>%{loginMethod}</method> to login, the password can not be change',
  'different-currency-hint': {
    'transaction-result': 'The actual amount will be charged in NT dollars according to the exchange rate',
    'transaction-detail': 'The actual amount will be charged in NT dollars according to the exchange rate',
    'default': 'The actual amount will be charged in NT dollars according to the exchange rate',
  },
  'bill-detail-TWD-price': '（ about %{symbol} %{price} ）',
  'patch-transaction-error-text': {
    [CommonErrors.ERR_INVALID_ARGUMENT]: 'Sorry! The creating of the order list is failed. Please try again.',
    [TransactionErrors.ERR_TRANSACTION_CHANGE_FAILED_ALREADY_PAID]: 'Already Paid.',
  },
  'patch-transaction-default-error': `Sorry! It didn't work. Please try again.`,
  'vendor-introduction': 'Vendor introduction',
  'limit-coupon-content': '*limited time coupon <limit>%{limit}</limit> pcs',
  'multi-piece-discount-hint': {
    title: 'Multi-piece discount:',
    text: ' from the purchase of the second piece of this product, the price of each piece is reduced by %{discount}',
  },
  'check-multi-piece-discount': {
    titleForConform: 'Conformity',
    titleForNotConform: 'Not ConForming',
    text: 'from the purchase of the second piece of this product, the price of each piece is reduced by %{discount}',
  },
  'vendor-page-notification-dialog': {
    title: 'Turn on “Seller Notifications”',
    text1: '👍 New Arrival Notifications',
    text2: '👍 Price Drop Alert',
    text3: '👍 Exclusive Event Notifications',
    'negative-btn-text': 'Not Now',
  },
  'vendor-page-notification-bar': {
    context1: 'Collect now!',
    context2: 'Won’t miss out new arrivals!',
    'button-text': 'Collect',
  },
  'share-link-success': 'share link success',
  'share-link-now': 'share invite link now',
  'share-link-mobile-message': 'Invite friends to earn bonuses\nEarn 50 Vcoins',
  'invite-friend-description': {
    'first-description': 'Invite your friends to join VeryBuy Fashion and enjoy Vcoin rewards and instant discounts on your purchases!',
    'second-description-register': 'Friend registers member and completes first order',
    'second-description-vcoin': '+50 Vcoin',
    'third-description': 'Invite a friend to become a VeryBuy member and complete the first order, you will receive 50 Vcoins, which can be credited at the checkout. There is no limit to the number of invitations, the more friends you invite, the more you get!',
  },
  'invite-friend-step': {
    'header-1': 'Step1: Share Link',
    'header-2': 'Step2: Friend registration member',
    'header-3': 'Step3: Friend completion order',
    'header-4': 'Step4: Receive Award',
    'description-1': '<text>Click <mark>"Copy"</mark> and then you can share the exclusive link with your friends. Or click <mark>"share invite link now"</mark> to easily share the link! </text>',
    'description-2': '<text>Friend click your <mark>"Exclusive Invitation Link"</mark> to successfully sign up as a VeryBuy Fashion member! </text>',
    'description-3': '<text>Friend completes first order and pays successfully! </text>',
    'description-4': '<text>After completing the above steps, the system will send the reward to <mark>"My Vcoins"</mark> in your account! </text>',
  },
  'suggest-vendor-title': 'Brands you might be looking for',
  'fuzzy-search-button-text': 'See more about',
  'company-information': '非常網路科技股份有限公司 ｜ 統編：53538135',
  'atm-maintaining-pay-by-other': 'ATM is maintaining, please use other methods',
  'fuzzy-search-discovery': 'You may want to look for',
  'try-to-use-other-condition': 'Try to use other condition!',
  'sales-amount': 'Sales Amount > %{amount}',
  'expand-details': 'Expand Details',
  'collapse-details': 'Collapse Details',
  'cart-shipping-hint': 'The actual shipping cost is based on the checkout page.',
  'bill-detail-shipping-hint-title': 'About Shipping Cost',
  'bill-detail-shipping-hint-context': 'Shipping charge cannot be discounted (coupons, vcoins...etc.), and free shipping if you reach the threshold',
  'bill-detail-cross-border-shipping-hint-title': 'About Shipping Cost',
  'bill-detail-cross-border-shipping-hint-context': 'Cross-border logistics fees include air (sea) transportation, over-dimensional material fees, pre-collected tariffs...etc. The actual fees will be based on the final billing details of the order, and the fees cannot be offset by discounts.',
  'bill-detail-event-vcoin-hint-title': 'About Vcoin',
  'bill-detail-event-vcoin-hint-context': 'Maximum discount of 15% of product subtotal',
  'reselect-coupon-alert': 'please select coupon again',
  'reselect-delivery-mode-alert': 'please select shipping method again',
  'try-this-combo': 'Try this combo',
  'change-batch': 'Change a batch',
  'watch-item': 'Watch Item',
  'select-shipping-method-alert': 'Please select shipping method first',
  'alert': 'Alert',
  'r18-modal': {
    'title': 'Adult Products Alert',
    'hint-text': 'This category is restricted',
    'trans-text': 'Only members above <highLight>18 years old</highLight> can view and purchase',
    'hint-sub-text': `VeryBuy Fashion content is handled in accordance with Taiwan's Internet Content Classification System, and may be accessed by persons who are 18 years of age or older, or who have reached the age of majority in their home country, and who are willing to accept the content of this site and its terms and conditions.`,
    'negative-title': `I'm under 18.`,
    'negative-text': 'Leave',
    'positive-title': `I'm above 18.`,
    'positive-text': 'Enter',
  },
};
