import { useCallback, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import IconNewNavRight from '../../assets/svg/IconNewNavRight';
import { warrior } from '../../constants/color';
import Picker from '../../elements/Picker';
import { countryCodeHandler } from '../../utils/countryInfo';
import showToast from '../../utils/showToast';
import S from './style';

// const items = Object.keys(countryInfo)
//   .filter(v => v === v.toUpperCase())
//   .map(v => ({ value: v, label: v }));

const items = [
  { value: 'TW', label: 'TW' },
  { value: 'HK', label: 'HK' },
  { value: 'SG', label: 'SG' },
  { value: 'US', label: 'US' },
];

const OnlyDevCountryCode = () => {
  const [countryCode, setCountryCode] = useState(countryCodeHandler.get);

  const onValueChange = useCallback(code => {
    setCountryCode(code);
  }, []);

  useUpdateEffect(() => {
    countryCodeHandler.set(countryCode);
    showToast(
      `OK, Set CountryCode to ["${countryCode}"], clean AsyncStorage if you want to reset`
    );
  }, [countryCode]);

  return (
    <S.RowItem style={{ flexDirection: 'row', alignItems: 'center' }}>
      <S.Label>Country Code:</S.Label>
      <Picker
        style={{
          marginLeft: 10,
          paddingVertical: 10,
          flex: 1,
        }}
        items={items}
        selectedValue={countryCode}
        onValueChange={onValueChange}
      />
      <IconNewNavRight size={12} fillColor={warrior} />
    </S.RowItem>
  );
};

export default OnlyDevCountryCode;
