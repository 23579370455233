import RNModal from 'react-native-modal';

import { isPrerender } from '../../deviceInfo';

export default class Modal extends RNModal {
  render() {
    if (isPrerender()) {
      return <></>;
    }

    return super.render();
  }
}
