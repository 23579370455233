import styled from 'styled-components/native';

import IconDone from '../../../assets/svg/IconDone';
import IconFailure from '../../../assets/svg/IconFailure';
import { white } from '../../../constants/color';
import { CONFIRM_R18_HINT } from '../../../constants/guideKeys';
import Image from '../../../elements/Image';
import NoScaleText from '../../../elements/NoScaleText';
import Touchable from '../../../elements/Touchable';
import useActions from '../../../hooks/useActions';
import { Trans, useTranslation } from '../../../i18n';
import { setGuideStatus } from '../../../redux/actions/tutorialGuideActions';
import Modal from '../../../utils/packages/reactNativeModal';

type Props = {
  isModalOpen: boolean;
  onNegativePress: () => void;
  onPositivePress: () => void;
};

const R18HintModal = (props: Props) => {
  const { t } = useTranslation();
  const TransComponents = {
    highLight: <S.HighLightText />,
  };

  const setGuideStatusAction = useActions(setGuideStatus);

  return (
    <Modal
      isVisible={props.isModalOpen}
      style={{ justifyContent: 'flex-end', margin: 0 }}
    >
      <S.Container>
        <S.Title>{t('r18-modal.title')}</S.Title>
        <S.Image source={require('./assets/img_patrol_230x140.png')} />
        <S.HintTexts>
          <S.HintText>{t('r18-modal.hint-text')}</S.HintText>
          <S.HintText>
            <Trans
              i18nKey="r18-modal.trans-text"
              components={TransComponents}
            />
          </S.HintText>
        </S.HintTexts>
        <S.HintSubText>{t('r18-modal.hint-sub-text')}</S.HintSubText>
        <S.Buttons>
          <S.NegativeButton onPress={props.onNegativePress}>
            <S.ButtonSubText>{t('r18-modal.negative-title')}</S.ButtonSubText>
            <S.ButtonMain>
              <S.IconWrapper>
                <IconFailure fillColor={white} size={16} />
              </S.IconWrapper>
              <S.ButtonMainText>
                {t('r18-modal.negative-text')}
              </S.ButtonMainText>
            </S.ButtonMain>
          </S.NegativeButton>
          <S.PositiveButton
            onPress={() => {
              setGuideStatusAction({ guideKey: CONFIRM_R18_HINT });
              props.onPositivePress();
            }}
          >
            <S.ButtonSubText>{t('r18-modal.positive-title')}</S.ButtonSubText>
            <S.ButtonMain>
              <S.IconWrapper>
                <IconDone fillColor={white} size={16} />
              </S.IconWrapper>
              <S.ButtonMainText>
                {t('r18-modal.positive-text')}
              </S.ButtonMainText>
            </S.ButtonMain>
          </S.PositiveButton>
        </S.Buttons>
      </S.Container>
    </Modal>
  );
};

const S = {
  Container: styled.View`
    padding: 25px;
    text-align: center;
    align-items: center;
    border-radius: 12px;
    background-color: ${({ theme }) => theme.palette.white};
  `,
  Title: styled(NoScaleText)`
    text-align: center;
    font-size: 18px;
    color: ${({ theme }) => theme.palette.warrior};
  `,
  HintTexts: styled.View`
    margin-bottom: 20px;
  `,
  HintText: styled(NoScaleText)`
    text-align: center;
    font-size: 15px;
    line-height: 21px;
    color: ${({ theme }) => theme.palette.warrior};
  `,
  HintSubText: styled(NoScaleText)`
    text-align: center;
    font-size: 13px;
    line-height: 21px;
    color: ${({ theme }) => theme.palette.neutralDark};
  `,
  Buttons: styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    width: 100%;
  `,
  PositiveButton: styled(Touchable)`
    padding: 10px;
    background-color: #4eb130;
    border-radius: 3px;
    flex: 1;
    height: 65px;
    margin-left: 12.5px;
  `,
  NegativeButton: styled(Touchable)`
    padding: 10px;
    background-color: ${({ theme }) => theme.palette.coral};
    flex: 1;
    border-radius: 3px;
    height: 65px;
    margin-right: 12.5px;
  `,
  ButtonSubText: styled(NoScaleText)`
    color: ${({ theme }) => theme.palette.white};
    font-size: 13px;
    text-align: center;
  `,
  ButtonMainText: styled(NoScaleText)`
    color: ${({ theme }) => theme.palette.white};
    font-size: 15px;
    font-weight: 500;
    margin-left: 5px;
    line-height: 24px;
  `,
  ButtonMain: styled.View`
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  `,
  Image: styled(Image)`
    margin: 20px;
    width: 230px;
    height: 140px;
    max-width: 100%;
    max-height: 100%;
  `,
  HighLightText: styled(NoScaleText)`
    text-align: center;
    font-size: 15px;
    color: ${({ theme }) => theme.palette.coral};
  `,
  CloseBtn: styled(Touchable)`
    position: absolute;
    right: 2px;
    top: 2px;
    padding: 10px;
  `,
  IconWrapper: styled.View``,
};

export default R18HintModal;
