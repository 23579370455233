import { Component } from 'react';

import { warrior } from '../../../constants/color';
import { Circle, G, Path, Svg } from '../../../utils/packages/reactNativeSvg';

const DEFAULT_SVG_SIZE = 24;

interface Props {
  size?: number;
  fillColor?: string;
}
interface DefaultProps {
  size: number;
  fillColor: string;
}

type PropsWithDefaults = Props & DefaultProps;

export default class IconCart extends Component<Props> {
  static defaultProps: DefaultProps = {
    size: DEFAULT_SVG_SIZE,
    fillColor: warrior,
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { size, fillColor } = this.props as PropsWithDefaults;
    const sizeString = size.toString();
    const scale = size / DEFAULT_SVG_SIZE;

    return (
      <Svg
        width={size}
        height={size}
        viewBox={`0 0 ${sizeString} ${sizeString}`}
      >
        <G fill={fillColor} scale={scale}>
          {/* eslint-disable-next-line max-len */}
          <Path d="M18.29 17.35H6.35a.62.62 0 0 1-.6-.46L2.31 3.9a.83.83 0 0 0-.72-.4h-1A.62.62 0 0 1 0 2.88a.61.61 0 0 1 .62-.61h1a2.06 2.06 0 0 1 1.85 1.22l.43 1.64H20A2.05 2.05 0 0 1 21.84 8l-3 9a.61.61 0 0 1-.55.35zM6.82 16.12h11l2.85-8.57a.84.84 0 0 0-.06-.82.8.8 0 0 0-.61-.36H4.23z" />
          <Circle cx="7.3" cy="19.91" r="1.23" />
          <Circle cx="17.33" cy="19.91" r="1.23" />
        </G>
      </Svg>
    );
  }
}
