/* eslint-disable max-len */
import { Component } from 'react';

import { warrior } from '../../../constants/color';
import { G, Path, Svg } from '../../../utils/packages/reactNativeSvg';

const DEFAULT_SVG_SIZE = 24;

interface Props {
  size?: number;
  fillColor?: string;
}
interface DefaultProps {
  size: number;
  fillColor: string;
}

type PropsWithDefaults = Props & DefaultProps;

export default class IconQuestion extends Component<Props> {
  static defaultProps: DefaultProps = {
    size: DEFAULT_SVG_SIZE,
    fillColor: warrior,
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { size, fillColor } = this.props as PropsWithDefaults;
    const sizeString = size.toString();
    const scale = size / DEFAULT_SVG_SIZE;

    return (
      <Svg
        width={size}
        height={size}
        viewBox={`0 0 ${sizeString} ${sizeString}`}
      >
        <G fill={fillColor} scale={scale}>
          <Path d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22ZM12,3.28A8.72,8.72,0,1,0,20.72,12,8.73,8.73,0,0,0,12,3.28Z" />
          {/* eslint-disable-next-line max-len */}
          <Path d="M11.89,14.78a.64.64,0,0,1-.64-.64V14a3.16,3.16,0,0,1,.38-1.85,3.87,3.87,0,0,1,.95-1l.2-.17c.64-.55,1.13-1,1.13-1.7A1.77,1.77,0,0,0,12,7.67a1.77,1.77,0,0,0-1.91,1.58.64.64,0,1,1-1.28,0A3,3,0,0,1,12,6.39a3,3,0,0,1,3.19,2.86,3.53,3.53,0,0,1-1.58,2.67l-.22.19a3.3,3.3,0,0,0-.68.66,2.15,2.15,0,0,0-.18,1.17v.2A.64.64,0,0,1,11.89,14.78Z" />
          <Path d="M11.89,17.61a.64.64,0,0,1-.63-.64v-.43a.64.64,0,1,1,1.27,0V17A.64.64,0,0,1,11.89,17.61Z" />
        </G>
      </Svg>
    );
  }
}
